<template>
  <div class="mobile">
    <div class="mobile-a">
      <div class="loginBtn" @click="clickImg(1)">注册登录</div>
      <img
        class="mobile-a-img"
        src="../../assets/mobileImg/mobile_1.png"
        alt=""
      />
      <img
        class="mobile-a-logo"
        src="../../assets/mobileImg/mobile_2.png"
        alt=""
      />
    </div>
    <div class="flex flex-between mobile-img">
      <img
        @click="clickImg(2)"
        src="../../assets/mobileImg/mobile_38.png"
        alt=""
      />
      <img
        @click="clickImg(2)"
        src="../../assets/mobileImg/mobile_39.png"
        alt=""
      />
    </div>
    <div class="flex flex-between mobile-b">
      <div class="mobile-b-item">
        <img
          class="mobile-b-item-img"
          src="../../assets/mobileImg/mobile_3.png"
        />
        <div class="mobile-b-item-name">证书服务</div>
        <a href="#anchor2"></a>
      </div>
      <div class="mobile-b-item">
        <img
          class="mobile-b-item-img"
          src="../../assets/mobileImg/mobile_4.png"
        />
        <div class="mobile-b-item-name">任务看板</div>
        <a href="#anchor3"></a>
      </div>
      <div class="mobile-b-item">
        <img
          class="mobile-b-item-img"
          src="../../assets/mobileImg/mobile_5.png"
        />
        <div class="mobile-b-item-name">关于我们</div>
        <a href="#anchor4"></a>
      </div>
    </div>
    <div class="mobile-c" id="anchor4">
      <div class="flex flex-center flex-wrap mobile-c-title">
        <div class="mobile-c-title-name">公司介绍</div>
        <i class="mobile-c-title-line"></i>
      </div>
      <div class="mobile-c-info">
        <div class="flex flex-left mobile-c-info-item">
          <div class="mobile-c-info-item-sx">
            <img src="../../assets/mobileImg/one.png" />
          </div>
          <div class="mobile-c-info-item-content">
            “有任务”专业带货达人培训考核串联品牌商家与带货达人的服务平台;
          </div>
        </div>
        <div class="flex flex-left mobile-c-info-item">
          <div class="mobile-c-info-item-sx">
            <img src="../../assets/mobileImg/two.png" />
          </div>
          <div class="mobile-c-info-item-content">
            “有任务”致力于成为集达人培训、考核、发证;品牌推广、销货营销;新媒体人才招聘的综合服务型平台;
          </div>
        </div>
        <div class="flex flex-left mobile-c-info-item">
          <div class="mobile-c-info-item-sx">
            <img src="../../assets/mobileImg/three.png" />
          </div>
          <div class="mobile-c-info-item-content">
            “有任务”秉承着“连接世界每一个光点，让所有人都能够看到你”的信念创立，致力于为品牌提供多种营销推广方式，连接多类型培训及营销机构，提供有效快捷的服务串联机制，未来将与品牌商户共同定义品牌推广服务。
          </div>
        </div>
      </div>
      <div class="mobile-c-work">
        <div class="mobile-c-work-title">有任务的愿景</div>
        <div class="flex flex-between mobile-c-work-list">
          <div class="mobile-c-work-list-item">
            <div class="flex flex-center">
              <img
                class="mobile-c-work-list-item-img"
                src="../../assets/mobileImg/mobile_6.png"
              />
            </div>
            <p class="mobile-c-work-list-item-title">建立链接</p>
            <p class="mobile-c-work-list-item-dec">
              根据标签及算法，<br />
              为商家推荐合适其需求的<br />
              带货网红资源
            </p>
          </div>
          <div class="mobile-c-work-list-item">
            <div class="flex flex-center">
              <img
                class="mobile-c-work-list-item-img"
                src="../../assets/mobileImg/mobile_7.png"
              />
            </div>
            <p class="mobile-c-work-list-item-title">电商运营</p>
            <p class="mobile-c-work-list-item-dec">
              为品牌商家提供各类社交平台、电商平台的达人带货解决方案
            </p>
          </div>
        </div>
        <div class="flex flex-between mobile-c-work-list">
          <div class="mobile-c-work-list-item">
            <div class="flex flex-center">
              <img
                class="mobile-c-work-list-item-img"
                src="../../assets/mobileImg/mobile_8.png"
              />
            </div>
            <p class="mobile-c-work-list-item-title">网红培育</p>
            <p class="mobile-c-work-list-item-dec">
              网罗全网达人培训机构进驻平台，并不断培养新晋达人通过培训、考核、发证、亮证等服务，提升达人的专业技能及技能背书
            </p>
          </div>
          <div class="mobile-c-work-list-item">
            <div class="flex flex-center">
              <img
                class="mobile-c-work-list-item-img"
                src="../../assets/mobileImg/mobile_9.png"
              />
            </div>
            <p class="mobile-c-work-list-item-title">定制服务</p>
            <p class="mobile-c-work-list-item-dec">
              以品牌需求导向， 提供定制化新媒体 及直播达人人才输送
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile-d" id="anchor3">
      <div class="flex flex-center flex-wrap mobile-c-title">
        <div class="mobile-c-title-name">有任务</div>
        <i class="mobile-c-title-line"></i>
        <div class="mobile-c-title-dec">
          让“品牌商家”与“带货达人”放心合作的平台
        </div>
      </div>
      <div class="flex flex-between mobile-d-info">
        <div class="mobile-d-info-item">
          <div class="flex flex-right flex-wrap">
            <p class="mobile-d-info-item-p1">商家根据需求发布任务</p>
            <img
              class="mobile-d-info-item-img1"
              src="../../assets/mobileImg/mobile_10.png"
            />
          </div>
          <div class="mobile-d-info-item-div1">
            <div class="flex flex-center">
              <img
                class="mobile-d-info-item-img2"
                src="../../assets/mobileImg/mobile_11.png"
              />
            </div>
            <div class="flex flex-center">
              <img
                class="mobile-d-info-item-img3"
                src="../../assets/mobileImg/mobile_12.png"
              />
            </div>
          </div>
          <div class="mobile-d-info-item-div2">
            <div
              class="flex flex-center align-center mobile-d-info-item-d mobile-d-info-item-d1"
            >
              寻找带货直播
            </div>
            <div
              class="flex flex-center align-center mobile-d-info-item-d mobile-d-info-item-d2"
            >
              商家审核
            </div>
            <div class="flex flex-center align-center mobile-d-info-item-d4">
              <div
                class="flex flex-center flex-wrap mobile-d-info-item-d4-line"
              >
                <div class="mobile-d-info-item-d4-line-a"></div>
                <div class="mobile-d-info-item-d4-line-b"></div>
              </div>
              <div class="mobile-d-info-item-d4-dec">
                根据任务<br />
                智能匹配<br />
                核定效果
              </div>
            </div>
            <div
              class="flex flex-center align-center mobile-d-info-item-d mobile-d-info-item-d3"
            >
              招聘相关人才
            </div>
          </div>
        </div>
        <div class="mobile-d-info-item">
          <div class="mobile-d-info-item-work">
            <div class="flex flex-center">
              <img
                class="mobile-d-info-item-img4"
                src="../../assets/mobileImg/mobile_13.png"
              />
            </div>
            <div class="flex flex-center">
              <img
                class="mobile-d-info-item-img3"
                src="../../assets/mobileImg/mobile_12.png"
              />
            </div>
          </div>
          <div class="mobile-d-info-item-div2">
            <div
              class="flex flex-center align-center mobile-d-info-item-d mobile-d-info-item-d1"
            >
              发布任务
            </div>
            <div
              class="flex flex-center align-center mobile-d-info-item-d mobile-d-info-item-d2"
            >
              商家审核
            </div>
            <div
              class="flex flex-center align-center mobile-d-info-item-d mobile-d-info-item-d3"
            >
              网红审核
            </div>
          </div>
        </div>
        <div class="mobile-d-info-item">
          <div class="flex flex-left flex-wrap">
            <p class="mobile-d-info-item-p1 mobile-d-info-item-p2">
              网红根据需求接受任务
            </p>
            <img
              class="mobile-d-info-item-img1"
              src="../../assets/mobileImg/mobile_14.png"
            />
          </div>
          <div class="mobile-d-info-item-div1">
            <div class="flex flex-center">
              <img
                class="mobile-d-info-item-img2"
                src="../../assets/mobileImg/mobile_15.png"
              />
            </div>
            <div class="flex flex-center">
              <img
                class="mobile-d-info-item-img3"
                src="../../assets/mobileImg/mobile_12.png"
              />
            </div>
          </div>
          <div class="mobile-d-info-item-div2">
            <div
              class="flex flex-center align-center mobile-d-info-item-d mobile-d-info-item-d1"
            >
              寻找带货直播
            </div>
            <div
              class="flex flex-center align-center mobile-d-info-item-d mobile-d-info-item-d2"
            >
              商家审核
            </div>
            <div class="flex flex-center align-center mobile-d-info-item-d4">
              <div
                class="flex flex-center flex-wrap mobile-d-info-item-d4-line"
              >
                <div class="mobile-d-info-item-d4-line-a"></div>
                <div class="mobile-d-info-item-d4-line-b"></div>
              </div>
              <div class="mobile-d-info-item-d4-dec">
                根据任务<br />
                智能匹配<br />
                核定效果
              </div>
            </div>
            <div
              class="flex flex-center align-center mobile-d-info-item-d mobile-d-info-item-d3"
            >
              招聘相关人才
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile-e" id="anchor2">
      <div class="flex flex-center flex-wrap mobile-c-title">
        <div class="mobile-c-title-name">证书介绍</div>
        <i class="mobile-c-title-line"></i>
        <div class="mobile-c-title-dec">
          有任务考核发展的相关职业技能证书均能<br />在以下官方网站上查询证书有效性
        </div>
        <div class="flex flex-center mobile-c-title-div">
          <div
            class="flex flex-center align-center mobile-c-title-tag mobile-c-title-tag1"
          >
            职业教育管理专业委员会<a
              href="http://www.rencaizhongxin.org.cn/"
            ></a>
          </div>
          <div class="flex flex-center align-center mobile-c-title-tag">
            智能人才查询<a href="https://www.zgzh.org.cn/"></a>
          </div>
        </div>
        <div class="flex flex-center align-center mobile-c-title-tag">
          中国管理科学学会培训中心<a href="https://www.msstc.org.cn/"></a>
        </div>
      </div>
      <div class="mobile-e-info">
        <div class="mobile-e-info-item">
          <div class="mobile-e-info-item-title">
            岗位名称：直播培训师（高级）
          </div>
          <div class="flex flex-left mobile-e-info-item-dec">
            <div class="mobile-e-info-item-dec-img">
              <img src="../../assets/mobileImg/mobile_16.png" />
            </div>
            <div class="mobile-e-info-item-dec-con">
              <p class="mobile-e-info-item-dec-con-p1">
                通过培训和考试获得直播现场场控、直播组织结构、直播设备运用、直播多平台运营基础规格等知识
              </p>
              <p class="mobile-e-info-item-dec-con-p2">
                能真正成为企业线上带货团队可执行、高效直播技能人才
              </p>
            </div>
          </div>
          <div class="mobile-e-info-item-pic">
            <img src="../../assets/mobileImg/mobile_17.png" />
          </div>
        </div>
        <div class="mobile-e-info-item">
          <div class="mobile-e-info-item-title">
            岗位名称：新媒体运营师（高级）
          </div>
          <div class="flex flex-left mobile-e-info-item-dec">
            <div class="mobile-e-info-item-dec-img">
              <img src="../../assets/mobileImg/mobile_16.png" />
            </div>
            <div class="mobile-e-info-item-dec-con">
              <p class="mobile-e-info-item-dec-con-p1">
                通过培训和考试获得新媒体运营平台基础操作、新媒体文案撰写基础、新媒体工具基础操作、新媒体数据分析等知识。
              </p>
              <p class="mobile-e-info-item-dec-con-p2">
                能真正成为企业新媒体运营工作上可执行人才。
              </p>
            </div>
          </div>
          <div class="mobile-e-info-item-pic">
            <img src="../../assets/mobileImg/mobile_18.png" />
          </div>
        </div>
        <div class="mobile-e-info-item">
          <div class="mobile-e-info-item-title">
            岗位名称：网络节目主持人（高级）
          </div>
          <div class="flex flex-left mobile-e-info-item-dec">
            <div class="mobile-e-info-item-dec-img mobile-e-info-item-dec-img1">
              <img src="../../assets/mobileImg/mobile_21.png" />
            </div>
            <div class="mobile-e-info-item-dec-con">
              <p class="mobile-e-info-item-dec-con-p1">
                通过培训和考试获得网络多类型主播的基础认知、网络主播的基础操作、网络主播软硬件设备基础认知、网络主播粉丝运营的知识。
              </p>
              <p class="mobile-e-info-item-dec-con-p2">
                能成为平台可执行的网络主播技能人才。
              </p>
            </div>
          </div>
          <div class="mobile-e-info-item-pic">
            <img src="../../assets/mobileImg/mobile_19.png" />
          </div>
        </div>
        <div class="mobile-e-info-item mobile-e-info-item1">
          <div class="mobile-e-info-item-title">岗位名称：主播导师（高级）</div>
          <div class="flex flex-left mobile-e-info-item-dec">
            <div class="mobile-e-info-item-dec-img mobile-e-info-item-dec-img1">
              <img src="../../assets/mobileImg/mobile_21.png" />
            </div>
            <div class="mobile-e-info-item-dec-con">
              <p class="mobile-e-info-item-dec-con-p1">
                通过培训和考试获得网络多类型主播的基础认知、网络节目场控、网络主播节目的策划、网络主播软硬件设备基础认知、网络主播运营和分析的基础知识。
              </p>
              <p class="mobile-e-info-item-dec-con-p2">
                能真正成为平台可执行的网络主播导师技能人才。
              </p>
            </div>
          </div>
          <div class="mobile-e-info-item-pic">
            <img src="../../assets/mobileImg/mobile_20.png" />
          </div>
        </div>
      </div>
    </div>
    <div class="mobile-f">
      <div class="flex flex-center flex-wrap mobile-c-title">
        <div class="mobile-c-title-name">
          提供给企业/品牌丰富匹配的<br />带货资源服务：任务看板
        </div>
        <i class="mobile-c-title-line"></i>
      </div>
      <div class="mobile-c-info">
        <div class="flex flex-left mobile-c-info-item">
          <div class="mobile-c-info-item-sx">
            <img src="../../assets/mobileImg/one1.png" />
          </div>
          <div class="mobile-c-info-item-content">
            品牌、企业可在“有任务”平台发布“带货、推广”相关的任务内容。
          </div>
        </div>
        <div class="flex flex-left mobile-c-info-item">
          <div class="mobile-c-info-item-sx">
            <img src="../../assets/mobileImg/two1.png" />
          </div>
          <div class="mobile-c-info-item-content">
            有任务线下有丰富的线上带货导购、网红达人的资源可查看和报名任务内容，可让企业可在对应垂直人才库里做高效筛选、找到可实际落地合作资源和人才。
          </div>
        </div>
        <div class="flex flex-left mobile-c-info-item">
          <div class="mobile-c-info-item-sx">
            <img src="../../assets/mobileImg/three1.png" />
          </div>
          <div class="mobile-c-info-item-content">
            有任务可为已拥有在有任务平台考核通过的学员做高证服务，帮助企业和品牌能做人才基础知识行业认知储备的识别。
          </div>
        </div>
      </div>
      <div class="mobile-f-dec">
        <div class="flex flex-between">
          <div class="mobile-f-dec-img1">
            <img src="../../assets/mobileImg/mobile_22.png" />
          </div>
          <div class="mobile-f-dec-img2">
            <p>会员查看品牌/企业<br />发布的任务，点击报名</p>
            <img src="../../assets/mobileImg/mobile_23.png" />
          </div>
        </div>
        <div class="flex flex-between">
          <div class="mobile-f-dec-img3">
            <img src="../../assets/mobileImg/mobile_24.png" />
            <p>
              品牌/企业可<br />查看会员的自<br />我相关领取经<br />验和技能证
            </p>
          </div>
          <div class="mobile-f-dec-img4">
            <img src="../../assets/mobileImg/mobile_25.png" />
          </div>
        </div>
      </div>
    </div>
    <div class="mobile-g">
      <div class="flex flex-center flex-wrap mobile-c-title">
        <div class="mobile-c-title-name">提供给企业/品牌带货渠道服务</div>
        <i class="mobile-c-title-line"></i>
      </div>
      <div class="mobile-c-info">
        <div class="flex flex-left mobile-c-info-item">
          <div class="mobile-c-info-item-sx">
            <img src="../../assets/mobileImg/one.png" />
          </div>
          <div class="mobile-c-info-item-content">
            品牌、企业在“有任务”成功注册并且认证审核通过后，可见销货申请的通道。
          </div>
        </div>
        <div class="flex flex-left mobile-c-info-item">
          <div class="mobile-c-info-item-sx">
            <img src="../../assets/mobileImg/two.png" />
          </div>
          <div class="mobile-c-info-item-content">
            帮助品牌和企业增加销货渠道。
          </div>
        </div>
      </div>
      <div class="mobile-g-dec">
        <div class="mobile-g-dec-title">销货申请</div>
        <div class="mobile-g-dec-img1">
          <img src="../../assets/mobileImg/mobile_26.png" />
        </div>
        <div class="flex flex-between mobile-g-dec-con">
          <div class="flex flex-between mobile-g-dec-con-d1">
            <img
              class="mobile-g-dec-con-d1-i1"
              src="../../assets/mobileImg/mobile_27.png"
            />
            <img
              class="mobile-g-dec-con-d1-i2"
              src="../../assets/mobileImg/mobile_28.png"
            />
            <div class="mobile-g-dec-con-d1-title">填写申请表单</div>
          </div>
          <div class="mobile-g-dec-con-d2">
            <img
              class="mobile-g-dec-con-d1-i2 mobile-g-dec-con-d2-i2"
              src="../../assets/mobileImg/mobile_29.png"
            />
            <img
              class="mobile-g-dec-con-d1-i1 mobile-g-dec-con-d2-i1"
              src="../../assets/mobileImg/mobile_30.png"
            />
            <div class="mobile-g-dec-con-d2-title">
              平台审核后，<br />会有渠道策划客户经理<br />和联系人沟通
            </div>
          </div>
        </div>
        <div class="flex flex-between mobile-g-dec-div">
          <p>成为有任务平台的<br />企业/品牌商</p>
          <p>策划+安排<br />进入渠道销货</p>
        </div>
      </div>
    </div>
    <div class="mobile-h">
      <div class="flex flex-center">
        <img class="mobile-h-img" src="../../assets/mobileImg/mobile_31.png" />
      </div>
      <div class="mobile-h-line"></div>
      <div class="mobile-h-info">
        <p class="flex flex-center mobile-h-info-p">
          重庆粟梵科技有限公司&nbsp;&nbsp;&copy;2022-2023&nbsp;版权所有<br />yourenwu.com.cn&nbsp;&nbsp;<span
            @click="clickLinkFunc"
            style="cursor: pointer"
            >渝ICP备2021012319号-3</span
          >
        </p>
        <p
          class="flex flex-center align-center mobile-h-info-p mobile-h-info-p1"
        >
          <img src="../../assets/mobileImg/mobile_32.png" />
          渝公网安备 50010802004624号
        </p>
      </div>
    </div>
    <div class="mobile-i">
      <img
        class="mobile-i-service"
        src="../../assets/mobileImg/mobile_34.png"
        @click="isWx = true"
      />
      <!-- <img class="mobile-i-wx" src="../../assets/mobileImg/mobile_34.png" > -->
    </div>
    <div class="mobile-wx" v-show="isWx">
      <div class="mobile-wx-main">
        <div class="mobile-wx-main-close" @click="closeFunc">
          <img src="../../assets/mobileImg/mobile_36.png" />
        </div>
        <div class="mobile-wx-main-title">商务企业微信号</div>
        <img class="mobile-wx-main-img" src="../../assets/img/qr2.png" />
      </div>
    </div>
    <!-- 达人登录 -->
    <div class="dialog" v-if="dialogTableVisible">
      <div class="dialog-box">
        <div class="dialog-box1">
          <img
            class="dialog-img"
            src="../../assets/mobileImg/mobile_36.png"
            alt=""
            @click="closeImg"
          />
          <div class="dialog-login">注册登录</div>
          <!-- <img class="dialog-img2" :src="imgValue" alt="" /> -->
          <img class="dialog-img2" src="../../assets/img/qr2.png" alt="" />
          <div class="dialog-text">长按识别</div>
        </div>
        <!-- <div class="dialog-box2" @click="downImg(imgValue)">保存小程序码</div> -->
      </div>
    </div>
  </div>
</template>
<script>
import imgSrc1 from "@/assets/img/login_person.jpg";
import imgSrc2 from "@/assets/img/login_com.jpg";
export default {
  data() {
    return {
      isWx: false,
      dialogTableVisible: false,
      titleValue: "达人登录",
      imgValue: null,
    };
  },
  methods: {
    closeFunc() {
      this.isWx = false;
    },
    clickLinkFunc() {
      window.location.href = "https://beian.miit.gov.cn/#/Integrated/index";
    },
    clickImg(ind) {
      console.log(111111111);
      this.titleValue = ind == 1 ? "达人登录" : "服务市场登录";
      this.imgValue = ind == 1 ? imgSrc1 : imgSrc2;
      this.dialogTableVisible = true;
    },
    closeImg() {
      this.dialogTableVisible = false;
    },
    downImg(imgSrc) {
      var a = document.createElement("a");
      a.download = name || "pic";
      // 设置图片地址
      a.href = imgSrc;
      a.click();
    },
  },
};
</script>
<style scoped>
.mobile {
  width: 100%;
  height: auto;
}
.mobile-a {
  position: relative;
  width: 100%;
  height: auto;
}
.mobile-a-img {
  width: 100%;
  height: auto;
  vertical-align: top;
}
.mobile-a-logo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 6.4rem;
  vertical-align: top;
}
.mobile-b {
  width: auto;
  height: auto;
  padding: 2.666666rem 5.866666rem 0.666666rem;
}
.mobile-b-item {
  position: relative;
  width: auto;
  height: auto;
}
.mobile-b-item-img {
  width: 6.4rem;
  height: 6.4rem;
  vertical-align: top;
  margin: 0 auto;
}
.mobile-b-item-name {
  position: relative;
  width: 100%;
  height: auto;
  color: #231815;
  font-size: 1.466666rem;
  font-weight: 400;
  text-align: center;
  margin-top: 0.4rem;
}
.mobile-b-item a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.mobile-c {
  width: auto;
  height: auto;
  padding: 0rem 2rem;
  margin-top: 4.666666rem;
}
.mobile-c-title {
  width: 100%;
  height: auto;
}
.mobile-c-title-name {
  width: 100%;
  height: auto;
  color: #333333;
  font-size: 2.666666rem;
  font-weight: 500;
  text-align: center;
}
.mobile-c-title-line {
  width: 2.666666rem;
  height: 0.4rem;
  margin-top: 1.066666rem;
  background-color: #08b2c1;
}
.mobile-c-info,
.mobile-c-info-item {
  width: 100%;
  height: auto;
}
.mobile-c-info {
  margin-top: 2.666666rem;
}
.mobile-c-info-item {
  margin-bottom: 2rem;
}
.mobile-c-info-item-sx {
  width: 1.866666rem;
  height: 2.133333rem;
}
.mobile-c-info-item-sx img {
  width: 1.866666rem;
  height: 2.133333rem;
  object-fit: contain;
  margin-top: 0.133333rem;
}
.mobile-c-info-item-content {
  width: auto;
  height: auto;
  flex: 1;
  color: #595961;
  font-size: 1.6rem;
  font-weight: 400;
  text-align: left;
  overflow: hidden;
  margin-left: 1.066666rem;
}
.mobile-c-work {
  width: auto;
  height: auto;
  margin-top: 3.333333rem;
  padding: 0rem 2rem 2rem;
  border-radius: 1.6rem;
  background-color: rgba(0, 157, 136, 0.03);
}
.mobile-c-work-title {
  width: 100%;
  height: auto;
  padding-top: 2rem;
  color: #009d88;
  font-size: 2.133333rem;
  font-weight: 500;
  text-align: center;
}
.mobile-c-work-list {
  width: 100%;
  height: auto;
  margin-top: 2rem;
}
.mobile-c-work-list-item {
  width: 20rem;
  height: auto;
  padding: 2rem 0rem;
  border-radius: 0.8rem;
  background-color: #fff;
}
.mobile-c-work-list-item-img {
  width: 8.533333rem;
  height: 8.533333rem;
  vertical-align: top;
}
.mobile-c-work-list-item-title {
  width: 100%;
  height: auto;
  color: #333333;
  font-size: 1.866666rem;
  font-weight: 600;
  text-align: center;
  margin-top: 0.8rem;
}
.mobile-c-work-list-item-dec {
  width: auto;
  height: auto;
  color: #999999;
  font-size: 1.466666rem;
  font-weight: 400;
  text-align: center;
  margin-top: 0.8rem;
  padding: 0rem 1.466666rem;
}
.mobile-d {
  width: auto;
  height: auto;
  padding: 4.666666rem 1.666666rem 5.333333rem;
  margin-top: 5.333333rem;
  background-color: #f7f7f7;
}
.mobile-c-title-dec {
  width: 100%;
  height: auto;
  color: #999999;
  font-size: 1.6rem;
  font-weight: 400;
  text-align: center;
  margin-top: 1.066666rem;
}
.mobile-d-info {
  width: 100%;
  height: auto;
  margin-top: 2.8rem;
}
.mobile-d-info-item {
  width: auto;
  height: auto;
  flex: 1;
  overflow: hidden;
}
.mobile-d-info-item-p1 {
  width: 100%;
  height: auto;
  color: #666666;
  font-size: 1.333333rem;
  font-weight: 400;
  padding-top: 3.333333rem;
  text-align: right;
  margin-bottom: 0.533333rem;
}
.mobile-d-info-item-p2 {
  text-align: left;
}
.mobile-d-info-item-img1 {
  width: 8.666666rem;
  height: 14.666666rem;
  vertical-align: top;
}
.mobile-d-info-item-div1 {
  padding: 0.8rem 0.266666rem 0rem 0rem;
  margin-bottom: 1.6rem;
}
.mobile-d-info-item-work {
  padding: 0rem;
  margin-bottom: 1.6rem;
}
.mobile-d-info-item-img2 {
  width: 10.666666rem;
  height: 10.666666rem;
  vertical-align: top;
}
.mobile-d-info-item-img3 {
  width: 1.733333rem;
  height: 1.866666rem;
  vertical-align: top;
  margin-top: 1.333333rem;
}
.mobile-d-info-item-img4 {
  width: 11.733333rem;
  height: 11.733333rem;
  vertical-align: top;
}
.mobile-d-info-item-div2 {
  width: 15.2rem;
  height: auto;
  padding: 2rem 0rem;
  border-radius: 1.333333rem;
  background-color: #fff;
}
.mobile-d-info-item-d {
  width: 12rem;
  height: 4rem;
  color: #009d88;
  font-weight: 500;
  font-size: 1.6rem;
  margin: 0 auto;
  border-radius: 0.666666rem;
  background-color: #e7f3f2;
}
.mobile-d-info-item-d1,
.mobile-d-info-item-d2 {
  margin-bottom: 1.333333rem;
}
.mobile-d-info-item-d4 {
  width: 12rem;
  height: auto;
  margin: 0 auto;
  margin-bottom: 1.333333rem;
}
.mobile-d-info-item-d4-line {
  width: 0.666666rem;
  height: auto;
}
.mobile-d-info-item-d4-line-a {
  width: 0.133333rem;
  height: 9.466666rem;
  background-color: #009d88;
}
.mobile-d-info-item-d4-line-b {
  width: 0;
  height: 0;
  border-top: 1.066666rem solid #009d88;
  border-left: 0.666666rem solid transparent;
  border-right: 0.666666rem solid transparent;
}
.mobile-d-info-item-d4-dec {
  width: auto;
  height: auto;
  color: #595961;
  font-size: 1.6rem;
  font-weight: 400;
  margin-left: 1.333333rem;
}
.mobile-e {
  width: auto;
  height: auto;
  padding: 5.333333rem 2rem;
}
.mobile-c-title-tag {
  position: relative;
  width: auto;
  height: 4.266666rem;
  color: #009d88;
  font-size: 1.6rem;
  font-weight: 400;
  padding: 0rem 1.6rem;
  border-radius: 4.266666rem;
  border: 0.066666rem solid #08b2c1;
}
.mobile-c-title-tag a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.mobile-c-title-div {
  margin-top: 2.666666rem;
  margin-bottom: 1.333333rem;
}
.mobile-c-title-tag1 {
  margin-right: 1.333333rem;
}
.mobile-e-info {
  width: 100%;
  height: auto;
}
.mobile-e-info-item {
  width: 100%;
  height: auto;
  padding: 4rem 0rem;
  border-bottom: 0.133333rem solid #ededed;
}
.mobile-e-info-item1 {
  padding: 4rem 0rem 0rem;
  border-bottom: 0rem solid #ededed;
}
.mobile-e-info-item-title {
  width: 100%;
  height: auto;
  color: #333333;
  font-size: 2.133333rem;
  font-weight: 500;
  text-align: left;
  padding-bottom: 2.666666rem;
}
.mobile-e-info-item-dec {
  width: 100%;
  height: auto;
}
.mobile-e-info-item-dec-img {
  width: 2.133333rem;
  height: 8.666666rem;
  margin-right: 1.066666rem;
}
.mobile-e-info-item-dec-img1 {
  height: 10.933333rem;
}
.mobile-e-info-item-dec-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin-top: 0.133333rem;
}
.mobile-e-info-item-dec-con {
  width: auto;
  height: auto;
  flex: 1;
  overflow: hidden;
}
.mobile-e-info-item-dec-con-p1,
.mobile-e-info-item-dec-con-p2 {
  width: 100%;
  height: auto;
  color: #595961;
  font-size: 1.6rem;
  font-weight: 400;
  text-align: left;
}
.mobile-e-info-item-dec-con-p1 {
  margin-bottom: 2rem;
}
.mobile-e-info-item-pic {
  width: 100%;
  height: auto;
  margin-top: 2.666666rem;
}
.mobile-e-info-item-pic img {
  width: 100%;
  height: auto;
  vertical-align: top;
}
.mobile-f {
  width: auto;
  height: auto;
  padding: 5.333333rem 2rem;
  background-color: #f7f7f7;
}
.mobile-f-dec {
  width: 100%;
  height: auto;
  margin-top: 4rem;
}
.mobile-f-dec-img1 {
  width: 25.333333rem;
  height: 31.066666rem;
}
.mobile-f-dec-img1 img,
.mobile-f-dec-img4 img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.mobile-f-dec-img2 {
  width: auto;
  height: auto;
  flex: 1;
  overflow: hidden;
  padding-top: 10.666666rem;
}
.mobile-f-dec-img2 p {
  width: auto;
  height: auto;
  color: #009d88;
  font-size: 1.466666rem;
  font-weight: 500;
  text-align: center;
  padding-left: 4.4rem;
}
.mobile-f-dec-img2 img {
  width: 16.666666rem;
  height: 16rem;
  vertical-align: top;
  margin-top: 0.666666rem;
}
.mobile-f-dec-img3 {
  width: auto;
  height: auto;
  flex: 1;
  overflow: hidden;
}
.mobile-f-dec-img3 img {
  width: 9.333333rem;
  height: 19.333333rem;
  vertical-align: top;
  margin-left: 1.6rem;
}
.mobile-f-dec-img3 p {
  width: auto;
  height: auto;
  color: #009d88;
  font-size: 1.466666rem;
  font-weight: 500;
  text-align: center;
  padding-right: 1.466666rem;
  margin-top: 1.2rem;
}
.mobile-f-dec-img4 {
  width: 34.4rem;
  height: 47.2rem;
}
.mobile-g {
  width: auto;
  height: auto;
  padding: 5.333333rem 2rem;
}
.mobile-g-dec {
  width: 100%;
  height: auto;
  margin-top: 4rem;
}
.mobile-g-dec-title {
  width: 100%;
  height: auto;
  color: #333333;
  font-size: 2rem;
  font-weight: 500;
  text-align: center;
  padding-bottom: 1.066666rem;
}
.mobile-g-dec-img1 {
  width: 38rem;
  height: 28rem;
  margin: 0 auto;
}
.mobile-g-dec-img1 img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.mobile-g-dec-con {
  width: 100%;
  height: auto;
}
.mobile-g-dec-con-d1,
.mobile-g-dec-con-d2 {
  position: relative;
  width: auto;
  height: auto;
  flex: 1;
  overflow: hidden;
}
.mobile-g-dec-con-d1 {
  margin-right: 5.733333rem;
}
.mobile-g-dec-con-d1-i1 {
  width: 10.666666rem;
  height: 10.666666rem;
  vertical-align: top;
  margin-left: 2.133333rem;
  margin-top: 16rem;
}
.mobile-g-dec-con-d1-i2 {
  width: 6.666666rem;
  height: 22.666666rem;
  vertical-align: top;
  margin-left: 0.533333rem;
}
.mobile-g-dec-con-d1-title {
  position: absolute;
  top: 10.4rem;
  left: 7.733333rem;
  width: auto;
  height: auto;
  color: #009d88;
  font-size: 1.466666rem;
  font-weight: 400;
}
.mobile-g-dec-con-d2-i2 {
  margin-left: 0rem;
}
.mobile-g-dec-con-d2-i1 {
  margin-left: 0.666666rem;
}
.mobile-g-dec-con-d2-title {
  position: absolute;
  top: 8.266666rem;
  left: 3.333333rem;
  width: auto;
  height: auto;
  color: #009d88;
  font-size: 1.466666rem;
  font-weight: 400;
  text-align: center;
}
.mobile-g-dec-div {
  width: auto;
  height: auto;
  padding: 0.933333rem 2.266666rem 0rem 0.933333rem;
}
.mobile-g-dec-div p {
  width: auto;
  height: auto;
  color: #333333;
  font-size: 1.733333rem;
  font-weight: 500;
  text-align: center;
}
.mobile-h {
  width: auto;
  height: auto;
  padding: 3.333333rem 2rem;
  background-color: #2b2d2d;
}
.mobile-h-img {
  width: 10.666666rem;
  height: 3.333333rem;
  vertical-align: top;
}
.mobile-h-line {
  width: 100%;
  height: 0.133333rem;
  margin-top: 2.666666rem;
  margin-bottom: 2.666666rem;
  background-color: #ffffff;
  opacity: 0.1;
}
.mobile-h-info {
  width: 100%;
  height: auto;
}
.mobile-h-info-p {
  width: 100%;
  height: auto;
  color: #8f9ba8;
  font-size: 1.333333rem;
  font-weight: 400;
  text-align: center;
}
.mobile-h-info-p img {
  width: 1.6rem;
  height: 1.866666rem;
  vertical-align: top;
  margin-right: 0.666666rem;
}
.mobile-h-info-p1 {
  margin-top: 0.666666rem;
}
.mobile-i {
  position: fixed;
  right: 2rem;
  bottom: 13.333333rem;
  z-index: 2;
  width: 5.866666rem;
  height: auto;
}
.mobile-i-service {
  width: 5.866666rem;
  height: 5.866666rem;
  vertical-align: top;
  margin-bottom: 1.333333rem;
}
.mobile-i-wx {
  width: 5.866666rem;
  height: 5.866666rem;
  vertical-align: top;
}
.mobile-wx {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11111;
  background-color: rgba(0, 0, 0, 0.5);
}
.mobile-wx-main {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 37.333333rem;
  height: 48rem;
  background: url(../../assets/mobileImg/mobile_35.png) no-repeat center center;
  background-size: contain;
}
.mobile-wx-main-close {
  position: absolute;
  top: -4.5rem;
  right: 0;
  width: 3.2rem;
  height: 3.2rem;
}
.mobile-wx-main-close img {
  width: 100%;
  height: 100%;
  vertical-align: top;
}
.mobile-wx-main-title {
  width: 100%;
  height: auto;
  padding: 2.8rem 0rem;
  color: #333333;
  font-size: 2.133333rem;
  font-weight: 400;
  text-align: center;
}
.mobile-wx-main-img {
  position: relative;
  left: 50%;
  top: 4.8rem;
  transform: translateX(-50%);
  width: 29.333333rem;
  height: 29.333333rem;
  vertical-align: top;
}

.loginBtn {
  position: absolute;
  top: 1.333333rem;
  right: 2rem;
  background: #ffffff;
  border-radius: 1.333333333rem;
  padding: 0.666666667rem 1.066666667rem;
  font-size: 1.6rem;
  color: #009d88;
  z-index: 100000;
}
.mobile-img {
  display: flex;
  justify-content: space-between;
  padding: 0 2.666666667rem;
  padding-top: 2rem;
}
.mobile-img img {
  width: 21rem;
  height: 12.933333333rem;
  background: rgba(8, 178, 193, 0.1);
  border-radius: 1.33333333rem;
}
/* 弹框 */
.dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(71, 70, 70, 0.37);
  z-index: 10000 !important;
}
.dialog-box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}
.dialog-box2 {
  width: 37.33333333rem;
  height: 5.8666666667rem;
  background: #ffffff;
  border-radius: 2.933333333rem;
  color: #009d88;
  font-weight: 500;
  font-size: 2rem;
  text-align: center;
  line-height: 5.8666666667rem;
  margin-top: 2rem;
}
.dialog-box1 {
  position: relative;
  width: 37.3333333333rem;
  height: 51.3333333333rem;
  padding: 2.8rem;
  box-sizing: border-box;
  background-image: url("../../assets/mobileImg/mobile_37.png");
  background-size: contain;
}
.dialog-img {
  width: 3.2rem;
  height: 3.2rem;
  position: absolute;
  right: 0;
  top: -4rem;
}
.dialog-img2 {
  margin: 0 auto;
  margin-top: 5.6rem;
  width: 32rem;
  height: 32rem;
}
.dialog-text {
  color: #666666;
  font-size: 1.866666667rem;
  text-align: center;
  margin-top: 2.66666667rem;
}
.dialog-login {
  text-align: center;
  font-size: 2.133333333rem;
  color: #333333;
}
</style>